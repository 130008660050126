<template>
  <div>
    <h1>欢迎使用,聚合支付</h1>
    {{ appId }}
    {{ nonceStr }}
    {{ packageVal }}
    {{ signType }}
    {{ timeStamp }}
    {{ paySign }}
    {{ orderTradeNo }}


  </div>
</template>
<script>

export default {
  name: "pay",
  data() {
    return {
      // appId: "",
      // nonceStr: "",
      // packageVal: "",
      // signType: "",
      // timeStamp: "",
      // paySign: "",
      // orderTradeNo: "",
      // form: ""
    };
  },
  mounted() {

    this.parseQueryParams()
  },
  methods: {

    parseQueryParams() {
      console.log(this.$route.fullPath.toString())
      let decodedParams = decodeURIComponent(this.$route.fullPath.toString());
      // console.log(decodedParams)
      // 删除文本中的第一个?之前的字符串
      //       const cleanedText = decodedParams.replace('/', '');
      let i = decodedParams.indexOf('?');
      // console.log(i)
      let a = decodedParams.substring(0, i + 1)
      decodedParams = decodedParams.replace(a, "")
      // 按照 '&' 分割文本
      const parts = decodedParams.split('&');
      const queryParamsObj = {};
      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const indexOfAmpersand = part.indexOf('='); // 找到第一个 '&' 的位置
        const key = part.substring(0, indexOfAmpersand); // 截取键
        // 截取值
        queryParamsObj[key] = part.substring(indexOfAmpersand + 1);
      }
      // console.log(queryParamsObj);

      if (queryParamsObj.expired === "true") {
        this.$message.error("二维码已过期");
      }
      // 将 this.$route.fullPath.toString()作为参数传递给下一个页面
      let originUri = this.$route.fullPath.toString()
      let index = originUri.indexOf('?');
      let b = originUri.substring(0, index + 1)
      originUri = originUri.replace(b, "")

      if (queryParamsObj.channel === 'WECHAT_PAY') {
        // this.appId = queryParamsObj.appId
        // this.nonceStr = queryParamsObj.nonceStr
        // this.packageVal = queryParamsObj.packageVal
        // this.signType = queryParamsObj.signType
        // this.timeStamp = queryParamsObj.timeStamp
        // this.paySign = queryParamsObj.paySign
        // this.orderTradeNo = queryParamsObj.orderTradeNo
        // 跳转微信支付的页面
        // 将解析完成的参数信息传递
        this.$router.push("/wechatPay?" + originUri);
        // this.payWxLoad()
      } else if (queryParamsObj.channel === 'ALI_PAY') {
        // 跳转阿里支付的页面
        this.$router.push("/aliPay?" + originUri);
        // this.payAliLoad()
      }

    },

    payAliLoad() {
      // 定义正则表达式模式来匹配参数
      const decodedParams = decodeURIComponent(this.$route.fullPath.toString());
      console.log(decodedParams)
      // 根据特定的分隔符拆分字符串
      const parts = decodedParams.split('&orderTradeNo=');
      // 提取 form 参数
      const form = parts[0].substring(parts[0].indexOf('<form'));
      // 提取 orderTradeNo 参数
      const orderTradeNo = parts[1].split('&channel=')[0];
      // 提取 channel 参数
      const channel = parts[1].split('&channel=')[1];
      this.form = form
      this.orderTradeNo = orderTradeNo
      this.channel = channel
      this.$message.success("正在调起支付宝支付");
      console.log("调起支付宝支付")
      document.querySelector("body").innerHTML = this.form;
      document.forms[0].submit();
    },

    payWxLoad() {
      this.$message.success("正在调起微信支付");
      console.log("调起微信支付")
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },


    onBridgeReady() {
      WeixinJSBridge.invoke('getBrandWCPayRequest', {
        "appId": this.appId,     //公众号ID，由商户传入
        "timeStamp": this.timeStamp,     //时间戳，自1970年以来的秒数
        "nonceStr": this.nonceStr,      //随机串
        "package": this.packageVal,
        "signType": this.signType,     //微信签名方式：
        "paySign": this.paySign //微信签名
      },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        });
    }
  },


}


</script>
<style scoped></style>